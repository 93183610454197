<template>
  <div class="rewardDetail root">
    <div class="back p-1 pr-2">
      <router-link to="/rewards" replace>
        <i class="icon-left-open-big"></i>กลับ
      </router-link>
    </div>
    <div class="content_segment">
      <img v-lazy="data.posterUrl" width="100%" alt="..." />
      <div class="rw_point">{{ $toLocal(data.redeemPoint) }} P</div>
      <div class="container">
        <div class="rw_title">{{ data.title }}</div>
        <div class="rw_des my-2 text-break" v-html="data.detail"></div>
        <!-- <div class="separate"></div> -->
      </div>
    </div>

    <div class="action_segment">
      <div class="row">
        <div class="col-4 text-center">
          <span class="align-middle">จำนวน</span>
        </div>
        <div class="col-8 text-center">
          <div @click="openEnterQty" class="qtyTxt">{{ quantity }}</div>
          <!-- <input id="quantityInput" v-model="quantity" type="number" class="form-control" /> -->
        </div>
      </div>
      <div class="w-100 text-center">
        <div v-if="checkExceedLimitPerUser">
          <button disabled id="exceedLimitPerUser" class="btn btn-light my-2">คุณแลกครบสิทธิ์แล้ว</button>
        </div>
        <div v-else-if="checkStock">
          <button
            v-if="checkPoint"
            :disabled="$readOnly"
            id="redeemBtn"
            @click="redeemClick()"
            class="btn btn-primary my-2"
            data-tag="redeem"
          >
            แลกของรางวัล
          </button>
          <button
            v-else
            disabled
            id="notEnoughPoint"
            class="btn btn-light my-2"
          >
            คะแนนไม่เพียงพอ
          </button>
        </div>
        <div v-else>
          <button disabled id="notEnoughStock" class="btn btn-light my-2">
            สินค้ามีไม่เพียงพอ
          </button>
        </div>
      </div>
    </div>

    <TermRedeemModal v-on:confirm="openAddAddress()" />

    <!-- modal address -->
    <b-modal id="address_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline"></i>
      </template>
      <AddAddress v-on:confirm-address="confirmAddress" />
    </b-modal>
    <!-- End modal address -->

    <transition name="list">
      <PinCodeInput
        v-if="enterPincode"
        v-on:already-pin="alreadyPin"
        v-on:close="closePinInput"
        :title="'กรุณาใส่ PIN'"
        :reset_pin="'ลืม PIN ?'"
        v-on:reset-pin="resetPin"
        :pin_length="6"
      />
    </transition>
    <transition name="list">
      <NumberInput
        v-if="enterQty"
        :title="'จำนวนสินค้า'"
        v-on:already="alreadyQty"
        v-on:close="closeQtyInput"
      />
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import PinCodeInput from "@/components/pinCodeInput";
import NumberInput from "@/components/numberInput";
import AddAddress from "@/components/addAddress";
import TermRedeemModal from "@/components/termRedeemModal";

export default {
  name: "rewardDetail",
  props: { reward_id: String },
  components: { PinCodeInput, NumberInput, AddAddress, TermRedeemModal },
  data() {
    return {
      quantity: 1,
      enterPincode: false,
      enterQty: false,
    };
  },
  computed: {
    checkPoint() {
      return this.$userData.point >= this.data.redeemPoint * this.quantity;
    },
    checkStock() {
      return !this.data?.isLimitQty ||
        this.data?.limitedQty - this.data?.redeemedQty - this.quantity >= 0
        ? true
        : false;
    },
    checkExceedLimitPerUser() {
      return  this.data?.limitPerUser > 0 && this.data?.userRedeemedQty >= this.data?.limitPerUser
    },
    rewardsData() {
      return this.$store.state.rewards.data;
    },
    data() {
      return this.rewardsData.find((item) => {
        return item.id == this.reward_id;
      });
    },
  },
  methods: {
    async fetchRedeem(pincodeInput) {
      const sendBody = {
        rewardId: this.data.id,
        quantity: parseInt(this.quantity),
        pincode: pincodeInput,
      };
      // process.env.VUE_APP_API_URL
      return await fetch(process.env.VUE_APP_API_URL + "/rewards/redeem", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.$JWT}`,
        },
        body: JSON.stringify(sendBody),
      });
    },
    async redeem(pincodeInput) {
      let body = {};
      try {
        window.loading(true);
        const res = await this.fetchRedeem(pincodeInput);
        console.log("fetchRedeem status:", res.status);
        body = await res.json();
        console.log("fetchRedeem body:", body);
        window.loading(false);

        window.$pushEvent("reward", "response", "redeem:" + res.status);
        switch (res.status) {
          case 200:
            this.whenRedeemSuccess(body);
            break;
          case 422:
            this.alertMsg(body);
            break;
          case 403:
            this.alertMsg(body);
            break;
          case 503:
            this.alertMsg("DATE_LIMIT");
            break;
          default:
            console.log("fetchRedeem: no case");
            this.alertMsg("error");
        }
      } catch (error) {
        window.loading(false);
        console.log(error);
        this.alertMsg("error");
      }
    },
    async whenRedeemSuccess(body) {
      try {
        // send message to chat
        await window.liff.sendMessages([
          {
            type: "text",
            text: `
รายละเอียดการแลกสินค้า
รหัสร้าน: ${body.shopCode}
วันที่แลกสินค้า: ${new Date(body.createdAt).toLocaleString("th-US", {
              hour12: false,
            })}
ชื่อสินค้า: ${this.data.title}
รหัสสินค้า: ${body.rewardId}
จำนวนที่แลก: ${body.quantity}
`,
          },
        ]);

        await window.liff.sendMessages([
          {
            type: "text",
            text: `
ขอขอบคุณลูกค้าที่ใช้คะแนนแลกของรางวัล โปรดเตรียมเอกสารในการออก หนังสือรับรองการหักภาษี ณ ที่จ่าย ให้ชัดเจนตามที่กรอกข้อมูลตอนแลกของรางวัล 
- ถ้าเปิดหน้าบัญชีในนามนิติบุคคล (บจก./หจก.) ให้เตรียม ภพ.20
- ถ้าเปิดหน้าบัญชีในนามบุคคลธรรมดาให้เตรียมสำเนาบัตรประชาชน

พร้อมเซ็นรับรองสำเนาถูกต้องระบุรหัสร้านค้าและชื่อร้านค้าตามหน้าบิลเสริมสุขและถ่ายรูปลงใน Line Sermsuk Family นี้ให้ชัดเจน
`,
          },
        ]);
      } catch (error) {
        console.log("send message line chat error:", error);
        // continue when error
      }

      this.Swal.fire(
        "ส่งข้อมูลสำเร็จ",
        "รอการดำเนินการส่งสินค้า",
        "success"
      ).then(() => {
        this.$router.replace("/rewards");
      });
    },
    async alertMsg(status, ...args) {
      window.$pushEvent("reward", "alert", "msg:" + status);

      switch (status) {
        case "POINT_NOT_ENOUGH":
          this.Swal.fire(
            "คะแนนไม่เพียงพอ",
            "กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่",
            "warning"
          );
          break;
        case "INVALID_PINCODE":
          this.Swal.fire({
            title: "PIN ไม่ถูกต้อง",
            text: "กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#eaa200",
            cancelButtonColor: "#a7a7a7",
            confirmButtonText: "Reset Pin",
            cancelButtonText: "กลับ",
          }).then((result) => {
            if (result.value) {
              this.resetPin();
            }
          });
          break;
        case "REACHED_TARGET":
          this.Swal.fire({
            html: `ขออภัยไม่สามารถแลกของรางวัลได้<br>เนื่องจากยอดสั่งซื้อสินค้าไม่ผ่านตามเป้าหมายที่กำหนดไว้ แต่คะแนนของท่านมีค่า สามารถเก็บคะแนนไว้สำหรับร่วมกิจกรรม<br>"เสริมสุขแฟมิลี่ Lucky Surprise" เดือน ก.ย.นี้`,
            icon: "warning",
          });
          break;
        case "DATE_LIMIT":
          this.Swal.fire({
            html: "สามารถแลกของรางวัลได้เมื่อจบรายการ<br>ตั้งแต่วันที่ 1 ม.ค. 2567 - 31 ต.ค. 2567<br>(คะแนนมีอายุ 30 วันหลังจากจบรายการ)",
            icon: "warning",
          });
          break;
        case "INPUT_QTY_EXCEED_REDEEMABLE_QTY":
          this.Swal.fire({
            title: "ขออภัย",
            html: `คุณสามารถแลกของรางวัลได้สูงสุด ${args[0]} ชิ้นต่อท่าน<br/>คุณสามารถแลกได้คงเหลือ ${args[1]} ชิ้น`,
            icon: "warning"}
          );
          break;
        default:
          this.Swal.fire({
            html: `แลกของรางวัลไม่ได้ในขณะนี้<br>กรุณาเข้าใช้ใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่`,
            icon: "warning",
          });
      }
    },
    redeemClick() {
      // Check by date
      // if (new Date() > new Date("2024-10-31 23:59:59")) {
      //   this.Swal.fire({
      //     html: `ขณะนี้รายการ SSC Family ได้จบรายการและปิดการรับแลกของรางวัลแล้ว และพบกันใหม่ในซีซั่นต่อไปในเร็วๆ นี้`,
      //     icon: "warning",
      //   });
      //   return;
      // }

      // check condition redeem
      // if (this.$store.state.user_data.customerSaleType != "RETAIL") {
      //   if (!this.data.redeemAble) {
      //     this.alertMsg("DATE_LIMIT");
      //     window.$pushEvent("reward", "alert", "DATE_LIMIT");
      //     return;
      //   }
      //   if (this.$store.state.user_data.isReachedTarget == 0) {
      //     this.alertMsg("REACHED_TARGET");
      //     window.$pushEvent("reward", "alert", "REACHED_TARGET");
      //     return;
      //   }
      // }

      //check correct qty
      if (this.quantity < 1) {
        this.Swal.fire("", "จำนวนขั้นต่ำคือ 1", "warning"); // alert
        this.quantity = 1; // reset qty
      } else {
        this.$bvModal.show("term_modal");
      }
    },
    openAddAddress() {
      this.$bvModal.show("address_modal");
      this.$bvModal.hide("term_modal");
    },
    openEnterPin() {
      this.enterPincode = true;
    },
    alreadyPin(val) {
      this.enterPincode = false;
      window.$pushEvent("reward", "event", "already_pin");
      this.redeem(val);
    },
    closePinInput(val) {
      if (val) {
        this.enterPincode = false;
      }
    },
    resetPin() {
      this.$router.replace({
        name: "otp",
        params: {
          route_back: this.$route,
          route_after_success: {
            name: "set-pin",
            params: {
              route_after_success: this.$route,
            },
          },
        },
      });
    },
    openEnterQty() {
      this.enterQty = true;
    },
    alreadyQty(val) {
      this.enterQty = false;
      if (this.data?.limitPerUser > 0) { // has limitPerUser
        const redeemableQty = Math.max(0, this.data.limitPerUser - this.data.userRedeemedQty) // handle negative result
        this.quantity = Math.min(val, redeemableQty); // qty should be in range 0 to redeemableQty
        val > redeemableQty && this.alertMsg("INPUT_QTY_EXCEED_REDEEMABLE_QTY", this.data.limitPerUser, redeemableQty)
      } else {
        this.quantity = val;
      }
    },
    closeQtyInput(val) {
      if (val) {
        this.enterQty = false;
      }
    },
    confirmAddress() {
      this.$bvModal.hide("address_modal");
      this.openEnterPin();
    },
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  // watch: {
  //   pinIn
  // },
  mounted() {
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
.back {
  display: inline-block;
  position: fixed;
  top: 6rem;
  margin-left: 10px;
  background: #d8d8d84b;
  font-size: 16px;
  border-radius: 30px;
}

.back a {
  color: #7b7b7b;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}

.root {
  font-size: 16px;
  position: relative;
}

.rw_point {
  padding: 3px 10px 3px 25px;
  text-align: center;
  font-size: 20px;
  right: 0;
  color: rgb(255, 255, 255);
  background: #b2d235;
  position: absolute;
  margin-top: -30px;
  font-weight: 500;
  z-index: 1;
  min-width: 100px;
  height: 45px;
  border-radius: 25px 0 0 25px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.rw_title {
  margin: 20px 0 0 0;
  font-size: 20px;
}

.rw_des {
  line-height: 22px;
}

.separate {
  margin: 0 auto;
  width: 60%;
  border-bottom: 1px solid #f0f0f0;
}

#redeemBtn,
#okBtn,
#notEnoughPoint {
  font-size: 16px;
  width: 80%;
  padding: 5px 0;
}

.qtyTxt {
  font-size: 18px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 12px;
}

input#quantityInput {
  height: 35px;
  letter-spacing: 5px;
  font-size: 16px;
  padding: 0;
  text-align: center;
}

.action_segment {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid rgb(248, 248, 248);
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  max-width: 450px;
}

.content_segment {
  min-height: 100vh;
  padding-bottom: 140px;
}
</style>
